import React from "react"
//import { Link, graphql } from "gatsby"
import { FlexCenter, LinearTitle } from "../styles/elements.js"
import styled from "styled-components"

import "normalize.css" // Note this

import Seo from "../components/Seo"
import Layout from "../components/layout.js"
import Featured from "../components/Featured"
import NetlifyForm from "../components/NetlifyForm.js"

import { Paragraph } from "./../styles/elements.js"

const Deal = props => {
  return (
    <>
      <Layout>
        <Seo title="About CompoundYourself" />
        <Featured height="75vh">
          <FlexCenter>
            <LinearTitle>Collaborate or Contribute</LinearTitle>
          </FlexCenter>
          <Paragraph>
            Compound Yourself is a community of Coders, Developers, Designers
            and Marketers, practicing a daily habit of making money online. Want
            to learn the tools of the trade?
          </Paragraph>

          <Margin />
          <LinearH1>
            Want to contribute with a blog, or have something else to discuss?
          </LinearH1>
          <Paragraph center>Contact us below.</Paragraph>
          <NetlifyForm />
        </Featured>
      </Layout>
    </>
  )
}

const Margin = styled.div`
  margin: 6rem 0rem;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`

const LinearH1 = styled.h1`
  margin: 0 auto;
  margin-bottom: 1.45rem;
  background: -webkit-linear-gradient(45deg, #ffc549, #ff6600, #ffc549);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  justify-content: center;
  word-wrap: break-word;
  //text-shadow: 2px 2px 5px goldenrod;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  max-width: 80%;
  font-size: 2.8rem;
  text-transform: uppercase;
  @media (min-width: 200px) {
    min-width: 95%;
    font-size: 2.8rem;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    min-width: 95%;
    font-size: 1.8rem;
  }
`

export default Deal
